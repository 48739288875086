.wrapper {
  grid-template-rows: min-content 1fr;
  grid-template-columns: repeat(12, 1fr);
  flex: 1 0 auto;
  gap: 0 20px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--basic-100);

  @media all and (max-width: 767px) {
    gap: 0;
  }
}

.findUrlPage {
  justify-items: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
}
