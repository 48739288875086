.select {
  border-radius: 4px;
  background-color: white;
}

label {
  color: white !important;
}

[aria-invalid='true'] {
  box-shadow: 0 0 0 1px var(--orange-5) !important;
  border-color: var(--orange-5) !important;
}
